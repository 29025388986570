//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Description",
  data: () => ({
    category: "Physical Health",
    symptoms: null
  }),
  computed: {
    disable() {
      return !this.category || !this.symptoms;
    }
  },
  methods: {
    onSubmit() {
      this.$emit("click", {
        category: this.category,
        symptoms: this.symptoms
      });
    }
  }
};