import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
import Description from "./Description";
import GrantAccessPHR from "./GrantAccessPHR";
export default {
  name: "RequestPage",
  data: () => ({
    isDescribing: true,
    isGrantingAccess: false,
    dataRequest: null,
    stepper: {
      1: "Describe your symptoms.\n          Choose which category it falls under; either mental or physical.",
      2: "Allow us to access your personal health records. The healthcare professional will use this information to provide you with an alternative solution."
    },
    step: 1
  }),
  computed: {
    stepperText() {
      return this.stepper[this.step].replace(/\n/g, "<br>");
    }
  },
  components: {
    Description,
    GrantAccessPHR
  },
  methods: {
    ...mapMutations({
      setSecondOpinionCategory: "secondOpinion/SET_SECOND_OPINION_CATEGORY",
      setSecondOpinionSymptoms: "secondOpinion/SET_SECOND_OPINION_SYMPTOMS"
    }),
    onSubmit(val) {
      this.setSecondOpinionCategory(val.category);
      this.setSecondOpinionSymptoms(val.symptoms);
      this.dataRequest = val;
      this.isDescribing = false;
      this.step = 2;
      this.isGrantingAccess = true;
    },
    toDescribeCard() {
      this.isGrantingAccess = false;
      this.step = 1;
      this.isDescribing = true;
    }
  }
};