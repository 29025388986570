import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from "vuex";
import { u8aToHex } from "@polkadot/util";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import cryptWorker from "@/common/lib/ipfs/crypt-worker";
import { getEMRCategories } from "@/common/lib/api";
import { registerElectronicMedicalRecord, registerElectronicMedicalRecordFee } from "@debionetwork/polkadot-provider";
import { queryElectronicMedicalRecordByOwnerId, queryElectronicMedicalRecordFileById } from "@debionetwork/polkadot-provider";
import { generalDebounce } from "@/common/lib/utils";
import errorMessage from "@/common/constants/error-messages";
import { validateForms } from "@/common/lib/validate";
import { errorHandler } from "@/common/lib/error-handler";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
import { fileTextIcon, alertIcon, pencilIcon, trashIcon, eyeOffIcon, eyeIcon } from "@debionetwork/ui-icons";
import { web3Enable, web3Accounts, web3FromAddress } from "@polkadot/extension-dapp";
import localStorage from "@/common/lib/local-storage";
import getEnv from "@/common/lib/utils/env";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || errorMessage.INPUT_CHARACTER("English alphabet");
export default {
  name: "GrantAccessPHR",
  mixins: [validateForms],
  data: () => ({
    phrList: [],
    grantedAccess: [],
    isLoading: false,
    isAddNewPHR: false,
    showModal: false,
    fileEmpty: false,
    disabledButton: false,
    showConnect: false,
    showModalConfirm: null,
    isConnectToMyriad: false,
    url: "",
    publicKey: null,
    secretKey: null,
    txWeight: null,
    error: null,
    clearFile: false,
    headers: [{
      text: "Health Record Title",
      value: "title",
      sortable: true
    }, {
      text: "Category",
      value: "category",
      sortable: true
    }, {
      text: "Document Title",
      value: "documentTitle",
      sortable: true
    }, {
      text: "Description",
      value: "documentDescription",
      sortable: true
    }],
    phr: {
      title: "",
      category: "",
      files: []
    },
    document: {
      title: "",
      description: "",
      file: null
    },
    categories: [],
    fileTextIcon,
    pencilIcon,
    trashIcon,
    eyeOffIcon,
    alertIcon,
    eyeIcon
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      mnemonicData: state => state.substrate.mnemonicData,
      web3: state => state.metamask.web3,
      walletBalance: state => state.substrate.walletBalance
    }),
    computeFiles() {
      return this.phr.files.map(file => ({
        ...file,
        percent: 0
      })).reverse();
    },
    disabledDocumentForm() {
      return this.document.title === "" || this.document.description === "" || this.document.file === null;
    },
    disabledFinish() {
      var _this$computeFiles;
      return (_this$computeFiles = this.computeFiles) === null || _this$computeFiles === void 0 ? void 0 : _this$computeFiles.some(file => file.percent !== 100);
    }
  },
  async created() {
    this.fetchCategories();
    await this.getPHRHistory();
    if (this.mnemonicData) this.initialDataKey();
  },
  watch: {
    async lastEventData(event) {
      if (event !== null) {
        const dataEvent = JSON.parse(event.data.toString());
        if (event.method === "ElectronicMedicalRecordAdded") {
          if (dataEvent[1] === this.wallet.address) {
            this.resetState();
            this.isLoading = false;
            this.isAddNewPHR = false;
            this.grantedAccess = [];
            await this.getPHRHistory();
          }
        }
      }
    },
    mnemonicData(val) {
      if (val) this.initialDataKey();
    },
    phr: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function (val) {
        this.txWeight = "Calculating...";
        const txWeight = await registerElectronicMedicalRecordFee(this.api, this.wallet, val);
        this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
      }, 500)
    }
  },
  rules: {
    password: [val => !!val || errorMessage.PASSWORD(8)],
    phr: {
      title: [val => !!val || errorMessage.REQUIRED, val => val && val.length < 50 || errorMessage.MAX_CHARACTER(50), englishAlphabet],
      category: [val => !!val || errorMessage.REQUIRED]
    },
    document: {
      title: [val => !!val || errorMessage.REQUIRED, val => val && val.length < 50 || errorMessage.MAX_CHARACTER(50), englishAlphabet],
      description: [val => !!val || errorMessage.REQUIRED, val => val && val.length < 255 || errorMessage.MAX_CHARACTER(255), englishAlphabet],
      file: [val => !!val || errorMessage.REQUIRED, val => val && val.size < 2000000 || errorMessage.FILE_SIZE(2), val => val && val.type === "application/pdf" || errorMessage.FILE_FORMAT("PDF")]
    }
  },
  methods: {
    ...mapMutations({
      setPHRIds: "secondOpinion/SET_PHR_IDS"
    }),
    initialDataKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    async fetchCategories() {
      this.categories = await getEMRCategories();
    },
    async redirectMyriad() {
      const url = "".concat(getEnv("VUE_APP_MYRIAD_URL"), "/");
      this.url = url;
      window.open(url, "_blank");
    },
    getBack() {
      this.$emit("back");
    },
    handleBack() {
      this.isAddNewPHR = false;
    },
    handleAddFile() {
      this.showModal = true;
      this.clearFile = false;
    },
    onDelete(id) {
      this.showModalConfirm = null;
      this.phr.files = this.phr.files.filter(file => file.createdAt !== id);
    },
    toSecondOpinion() {
      const ids = this.grantedAccess.map(data => data.id);
      this.setPHRIds(ids);
      this.showConnect = true;
    },
    async toContinue() {
      const sender = this.wallet.address;
      const allInjected = await web3Enable("Debio Network");
      if (!allInjected) return this.isNotInstalled = false;
      const allAccounts = await web3Accounts();
      if (!allAccounts.length) await this.exportKeystoreAction();
      const account = allAccounts.find(account => account.address === sender);
      if (!account) await this.exportKeystoreAction();
      const injector = await web3FromAddress(sender);
      if (injector) {
        this.showConnect = false;
        // this.isConnectToMyriad = true
        this.$router.push({
          name: "connecting-page"
        });
      }
    },
    exportKeystoreAction() {
      try {
        const keystore = localStorage.getKeystore();
        const address = localStorage.getAddress();
        const file = new Blob([keystore], {
          type: "text/json;charset=utf-8"
        });
        const downloadUrl = window.URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "".concat(address, ".json");
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {}
    },
    async getPHRHistory() {
      this.showModal = false;
      this.isLoading = true;
      const documents = [];
      try {
        const dataPHR = await queryElectronicMedicalRecordByOwnerId(this.api, this.wallet.address);
        if (dataPHR !== null || !(dataPHR !== null && dataPHR !== void 0 && dataPHR.length)) {
          const listPHR = dataPHR.reduce((filtered, current) => {
            if (filtered.every(v => v.id !== current.id)) filtered.push(current);
            return filtered;
          }, []);
          listPHR.reverse();
          for (const phrDetail of listPHR) {
            const documentDetail = await this.preparePHRData(phrDetail);
            documents.push(documentDetail);
          }
        }
        this.phrList = documents;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async preparePHRData(dataPHR) {
      let files = [];
      for (let i = 0; i < ((_dataPHR$files = dataPHR.files) === null || _dataPHR$files === void 0 ? void 0 : _dataPHR$files.length); i++) {
        var _dataPHR$files;
        const file = await queryElectronicMedicalRecordFileById(this.api, dataPHR.files[i]);
        dataPHR.createdAt = new Date(+file.uploadedAt.replaceAll(",", "")).toLocaleDateString("id", {
          day: "2-digit",
          month: "short",
          year: "numeric"
        });
        files.push({
          ...file,
          createdAt: new Date(+file.uploadedAt.replaceAll(",", "")).toLocaleDateString("id", {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }),
          recordLink: file.recordLink
        });
      }
      return {
        id: dataPHR.id,
        title: dataPHR.title,
        category: dataPHR.category,
        createdAt: dataPHR.createdAt,
        files: files === null || files === void 0 ? void 0 : files.slice(0, 3)
      };
    },
    async finalSubmit() {
      this.isLoading = true;
      this.disabledButton = true;
      try {
        if (this.phr.files.length === 0) return;
        for await (let [index, value] of this.phr.files.entries()) {
          const dataFile = await this.setupFileReader({
            value
          });
          await this.upload({
            encryptedFileChunks: dataFile.chunks,
            fileName: dataFile.fileName,
            fileSize: dataFile.fileSize,
            index: index,
            fileType: dataFile.fileType
          });
        }
        await registerElectronicMedicalRecord(this.api, this.wallet, this.phr);
      } catch (e) {
        const error = await errorHandler(e.message);
        this.error = error;
        this.isLoading = false;
        this.disabledButton = false;
      }
    },
    setupFileReader(_ref) {
      let {
        value
      } = _ref;
      return new Promise((resolve, reject) => {
        const file = value.file;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    handleNewFile() {
      var _this$isDirty;
      if (Number(this.walletBalance) < Number(this.txWeight.split(" ")[0])) {
        this.error = {
          title: "Insufficient Balance",
          message: "Your transaction cannot go through because your account balance is too low or doesn't meet the minimum deposit needed. Please check your balance."
        };
        return;
      }
      this._touchForms("document");
      const {
        title: docTitle,
        description: docDescription,
        file: docFile
      } = (_this$isDirty = this.isDirty) === null || _this$isDirty === void 0 ? void 0 : _this$isDirty.document;
      if (docTitle || docDescription || docFile) return;
      const context = this;
      const fr = new FileReader();
      const {
        title,
        description,
        file
      } = this.document;
      fr.onload = async function () {
        try {
          const encrypted = await context.encrypt({
            text: fr.result,
            fileType: file.type,
            fileSize: file.size,
            fileName: file.name
          });
          const {
            chunks,
            fileName,
            fileType,
            fileSize
          } = encrypted;
          const dataFile = {
            title,
            description,
            file,
            chunks,
            fileName,
            fileSize,
            fileType,
            createdAt: new Date().getTime()
          };
          context.phr.files.push(dataFile);
        } catch (e) {
          this.error = e.message;
        }
      };
      fr.readAsArrayBuffer(file);
      this.onCloseModalDocument();
    },
    async encrypt(_ref2) {
      let {
        text,
        fileType,
        fileName,
        fileSize
      } = _ref2;
      const context = this;
      const arrChunks = [];
      let chunksAmount;
      const pair = {
        secretKey: this.secretKey,
        publicKey: this.publicKey
      };
      const data = await new Promise((resolve, reject) => {
        try {
          cryptWorker.workerEncryptFile.postMessage({
            pair,
            text,
            fileType
          }); // Access this object in e.data in worker
          cryptWorker.workerEncryptFile.onmessage = async event => {
            if (event.data.chunksAmount) {
              chunksAmount = event.data.chunksAmount;
              return;
            }
            arrChunks.push(event.data);
            context.encryptProgress = arrChunks.length / chunksAmount * 100;
            if (arrChunks.length === chunksAmount) {
              resolve({
                fileName,
                fileSize,
                fileType,
                chunks: arrChunks
              });
            }
          };
          this.fileEmpty = false;
        } catch (err) {
          reject(new Error(err.message));
        }
      });
      return data;
    },
    async upload(_ref3) {
      let {
        encryptedFileChunks,
        index,
        fileType,
        fileName,
        fileSize
      } = _ref3;
      const data = JSON.stringify(encryptedFileChunks);
      const blob = new Blob([data], {
        type: fileType
      });
      const result = await uploadFile({
        title: fileName,
        type: fileType,
        size: fileSize,
        file: blob
      });
      const link = getFileUrl(result.IpfsHash);
      this.phr.files[index].recordLink = link;
    },
    onCloseModalDocument() {
      this.showModal = false;
      Object.assign(this.document, {
        title: "",
        description: "",
        file: null
      });
      this.clearFile = true;
      this._resetForms("document");
    },
    async handleSubmit() {
      var _this$isDirty2, _this$isDirty3;
      if (Number(this.walletBalance) < Number(this.txWeight.split(" ")[0])) {
        this.error = {
          title: "Insufficient Balance",
          message: "Your transaction cannot go through because your account balance is too low or doesn't meet the minimum deposit needed. Please check your balance."
        };
        return;
      }
      this._touchForms("phr");
      const isPHRValid = Object.values((_this$isDirty2 = this.isDirty) === null || _this$isDirty2 === void 0 ? void 0 : _this$isDirty2.phr).every(v => v !== null && v === false);
      const isDocumentValid = Object.values((_this$isDirty3 = this.isDirty) === null || _this$isDirty3 === void 0 ? void 0 : _this$isDirty3.document).every(v => v !== null && v === false);
      if (!isPHRValid || !isDocumentValid && this.phr.files.length < 1) {
        if (!this.phr.files.length) this.fileEmpty = true;
        return;
      }
      this.fileEmpty = false;
      this.clearFile = true;
      await this.finalSubmit();
    },
    resetState() {
      Object.assign(this.phr, {
        title: "",
        category: "",
        files: []
      });
      Object.assign(this.document, {
        title: "",
        description: "",
        file: null
      });
    }
  }
};